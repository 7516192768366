<template>
  <v-card elevation="0" max-width="552px" width="100%">
    <v-btn
      color="primary"
      rounded
      large
      variant="elevated"
      class="mt-6 login__form-section__container__submit-btn"
      @click.prevent="auth0Login"
    >
      LOG IN
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "LoginForm",

  methods: {
    auth0Login() {
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
