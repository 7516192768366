<template>
  <v-main class="authentication">
    <section class="authentication-container d-flex">
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="authentication__image-section pa-4 d-flex flex-column justify-space-between flex-start"
      >
        <logo-with-text-icon class="authentication__logo" />
        <v-img src="@/assets/login-background.png" />
        <article class="mb-2">
          <h2 class="mb-0">Improving Patient Care, one audit at a time.</h2>
        </article>
      </div>
      <div
        v-else
        class="authentication__header d-flex flex-row justify-start align-center"
      >
        <logo-with-text-icon />
      </div>
      <div
        class="authentication__form-section d-flex flex-column justify-center align-center pa-4"
      >
        <LoginForm />
      </div>
    </section>
  </v-main>
</template>

<script>
import { validationMixin } from "vuelidate";
import LogoWithTextIcon from "@/components/icons/LogoWithTextIcon.vue";
import LoginForm from "@/components/authentication/LoginForm.vue";

export default {
  name: "AuthenticationPage",

  components: {
    LogoWithTextIcon,
    LoginForm,
  },

  mixins: [validationMixin],
};
</script>

<style lang="scss">
.authentication {
  &.v-main {
    padding-top: 0 !important;
  }

  max-height: 100vh;

  @media (max-width: 768px) {
    &-container {
      flex-direction: column;
    }
  }

  &-container {
    width: 100%;
    height: 100%;
  }

  &__header {
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #c0c0c0;
  }

  &__logo {
    position: relative;
    z-index: 1;
  }

  &__image-section {
    max-width: 480px;
    position: relative;

    .v-image {
      z-index: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }

    svg path {
      fill: var(--v-on-primary-base) !important;
    }

    article {
      color: var(--v-on-primary-base);

      h2 {
        font-size: 3rem;
        line-height: 1.2;
        margin-bottom: 20px;
      }
    }

    @media (max-width: 992px) {
      max-width: 420px;
    }
  }

  &__form-section {
    height: 100%;
    width: 100%;
  }
}
</style>
